/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action, computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteHeaderDetails extends Component {
  @service websiteWidget;
  @service currentPractice;
  @service session;

  @reads('openContactWidgetTask.isRunning') isLoading;
  @reads('currentPractice.featurePracticeContactForm') featurePracticeContactForm;

  init() {
    super.init(...arguments);

    if (this.featurePracticeContactForm && this.model.practiceContactForm) {
      this.session.set('channel', 'professional_website');
    }
  }

  @computed(
    'currentPractice.practiceContactForm',
    'model.practiceContactForm',
    'model.showContactCta',
    'toggleContactModal'
  )
  get showContactForm() {
    if (this.featurePracticeContactForm) return this.model.practiceContactForm;

    return this.model.showContactCta && this.toggleContactModal;
  }

  @action
  onContactClick() {
    this.featurePracticeContactForm
      ? this.websiteWidget.openContactWidgetTask.perform()
      : this.toggleContactModal();
  }
}
