import classic from 'ember-classic-decorator';
/* global analytics */
import { getTrackingStatus } from 'client-portal/utils/sample-track';
import Service, { service } from '@ember/service';
import config from 'client-portal/config/environment';

const SOURCE_APPLICATION = 'Client Portal';
const INITIATOR = 'User';

@classic
export default class AnalyticsService extends Service {
  @service currentPractice;
  @service cookies;
  @service router;

  clientIdentified = false;
  clientProperties = {};
  isSampling = false;
  isWidget = true;
  trackingEnabled = true;

  init() {
    super.init(...arguments);

    if (config.analyticsEnvironment === 'PROD') {
      this.isSampling = true;
    }

    this.isWidget = this.router.location.path?.startsWith('/widget');

    this.trackingEnabled = getTrackingStatus(this.cookies, this.isWidget, this.isSampling);
  }

  identify(currentClient) {
    if (
      typeof analytics === 'undefined' ||
      this.clientIdentified ||
      !currentClient ||
      !this.trackingEnabled
    ) {
      return;
    }

    this.set('clientIdentified', true);
    this.set('clientProperties', {
      'client_id': currentClient.hashedId,
      'practice_id': this.get('currentPractice.id'),
    });

    let identifyProperties = {
      'practice_id': this.clientProperties.practice_id,
      'source_application': SOURCE_APPLICATION,
    };

    analytics.identify(currentClient.hashedId, identifyProperties);
  }

  track(eventName, properties = {}) {
    if (!this.clientIdentified || !this.trackingEnabled) {
      return;
    }

    let eventProperties = {
      'initiator': INITIATOR,
      'source_application': SOURCE_APPLICATION,
      ...this.clientProperties,
      ...properties,
    };

    analytics.track(eventName, eventProperties);
  }

  trackAnonymously(eventName, properties = {}) {
    if (typeof analytics === 'undefined' || !this.trackingEnabled) {
      return;
    }

    let eventProperties = {
      'practice_id': this.get('currentPractice.id'),
      'initiator': INITIATOR,
      'source_application': SOURCE_APPLICATION,
      ...properties,
    };

    analytics.track(eventName, eventProperties);
  }

  selectiveTrack(eventName, properties = {}) {
    this.clientIdentified
      ? this.track(eventName, properties)
      : this.trackAnonymously(eventName, properties);
  }

  clearAttributes() {
    this.set('clientIdentified', false);
    this.set('clientProperties', {});
  }

  get anonymousId() {
    if (
      typeof analytics === 'undefined' ||
      typeof analytics.user !== 'function' ||
      !this.trackingEnabled
    ) {
      return null;
    }

    return analytics.user().anonymousId();
  }
}
