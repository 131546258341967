import { htmlSafe } from '@ember/template';
import { requiredString } from '../../../../utils/component-validations';
import Component from '@glimmer/component';

export default class SpdsAlertBannerMessageBody extends Component {
  constructor() {
    super(...arguments);

    requiredString({
      argument: this.args.body,
      component: 'Spds::AlertBanner::Message',
      name: 'body',
    });
  }

  get content() {
    return htmlSafe(this.args.body);
  }
}
