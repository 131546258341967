import { WEBSITE_WIDGET_CHANNEL } from 'client-portal/services/website-widget';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ContactWidgetRoute extends Route {
  @service store;
  @service currentPractice;
  @service router;
  @service session;

  queryParams = {
    clinicianId: { refreshModel: true },
  };

  beforeModel() {
    super.beforeModel(...arguments);

    if (
      !this.currentPractice.get('featurePracticeContactForm') ||
      (!this.currentPractice.get('practiceContactForm') &&
        !this.currentPractice.get('monarchContactForm'))
    ) {
      this.router.transitionTo('home');
    }
  }

  async model({ clinicianId }, _transition) {
    let clinician = clinicianId ? await this.store.findRecord('clinician', clinicianId) : null;
    let client = this.store.createRecord('prospective-client', {
      emails: [this.store.createRecord('email', { prospectiveClient: true })],
      phones: [this.store.createRecord('phone', { prospectiveClient: true })],
      globalMonarchChannelName: this.channelName,
      clinician,
    });
    return { client, clinician };
  }

  get channelName() {
    let channel = this.session.channel;

    return !channel || channel === WEBSITE_WIDGET_CHANNEL ? 'client_portal' : channel;
  }
}
