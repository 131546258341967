import { optionalAction, requiredString } from '../../../../utils/component-validations';
import Component from '@glimmer/component';

export default class SpdsAlertBannerMessageCta extends Component {
  constructor() {
    super(...arguments);

    this.validateArguments();
  }

  validateArguments() {
    let { onPrimaryBtnClick, onSecondaryBtnClick, primaryBtnText, secondaryBtnText } = this.args;

    optionalAction({
      action: onPrimaryBtnClick,
      component: 'Spds::AlertBanner::Message',
      name: 'onPrimaryBtnClick',
    });

    optionalAction({
      action: onSecondaryBtnClick,
      component: 'Spds::AlertBanner::Message',
      name: 'onSecondaryBtnClick',
    });

    if (onPrimaryBtnClick) {
      requiredString({
        argument: primaryBtnText,
        message:
          '@primaryBtnText argument for Spds::AlertBanner::Message is required when passing @onPrimaryBtnClick',
      });
    }

    if (onSecondaryBtnClick) {
      requiredString({
        argument: secondaryBtnText,
        message:
          '@secondaryBtnText argument for Spds::AlertBanner::Message is required when passing @onSecondaryBtnClick',
      });
    }
  }
}
