import { modifier } from 'ember-modifier';
import { validateArgumentList } from '../../utils/component-validations';
import Component from '@glimmer/component';
import config from 'ember-get-config';

export default class SpdsValidatableComponent extends Component {
  validateArguments = modifier(() => {
    if (!['development', 'test'].includes(config.environment)) return;

    let { args, componentName, constructor, arguments: argumentList } = this;

    validateArgumentList({ args, argumentList, component: componentName || constructor.name });
  });
}
