/* import __COLOCATED_TEMPLATE__ from './site-footer.hbs'; */
import { action, computed } from '@ember/object';
import { bool, match, notEmpty, or, reads } from '@ember/object/computed';
import { classNameBindings, classNames, tagName } from '@ember-decorators/component';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './site-footer.module.scss';

@classic
@tagName('footer')
@classNames('site-footer', styles.component)
@classNameBindings('isEditMode:edit-mode')
export default class SiteFooter extends Component {
  @service currentPractice;
  @service analytics;
  @service mixpanel;
  @service router;
  @service session;
  @service websiteWidget;

  @reads('currentPractice.defaultMarketingOffice') defaultMarketingOffice;
  @reads('currentPractice.defaultOffice') defaultOffice;
  @reads('currentPractice.defaultPhoneNumber') defaultPhoneNumber;
  @reads('currentPractice.hasCustomDomain') hasCustomDomain;
  @bool('office') hasOffice;
  @bool('practiceWebsite') hasPracticeWebsite;
  @or('practiceWebsite.practiceName', 'currentPractice.fullName') practiceName;
  @reads('practiceWebsite.showSectionLocations') showSectionLocations;
  @notEmpty('socialMediaLinks') showSocialMedia;
  @reads('router.currentRouteName') currentRouteName;
  @match('currentRouteName', /home/) isHomeRoute;
  @reads('currentPractice.featurePracticeContactForm') featurePracticeContactForm;

  @computed(
    'featurePracticeContactForm',
    'practiceWebsite.practiceContactForm',
    'practiceWebsite.showContactCta',
    'currentPractice.practiceContactForm',
    'toggleContactModal'
  )
  get showContactCta() {
    if (this.featurePracticeContactForm)
      return (
        this.practiceWebsite?.practiceContactForm ?? this.currentPractice.get('practiceContactForm')
      );

    return this.practiceWebsite?.showContactCta && this.toggleContactModal;
  }

  @computed('currentPractice.practiceWebsite', 'edit', 'model')
  get practiceWebsite() {
    return this.edit
      ? this.model // NOTE: use draft practiceWebsite model when in website editor
      : this.currentPractice.get('practiceWebsite');
  }

  @computed('practiceWebsite', 'isHomeRoute')
  get hideHelpCenterLink() {
    return this.isHomeRoute && isPresent(this.practiceWebsite);
  }

  @computed('defaultOffice.city', 'hasPracticeWebsite', 'practiceWebsite.practiceCity')
  get city() {
    return this.hasPracticeWebsite ? this.practiceWebsite.practiceCity : this.defaultOffice?.city;
  }

  @computed('hasCustomDomain')
  get linkRel() {
    return this.hasCustomDomain ? 'sponsored' : 'noopener noreferrer nofollow';
  }

  @computed(
    'defaultMarketingOffice',
    'defaultOffice',
    'edit',
    'marketingOffice',
    'hasPracticeWebsite'
  )
  get office() {
    if (this.edit) {
      return this.marketingOffice; // NOTE: use draft marketingOffice when in website editor
    }
    return this.hasPracticeWebsite ? this.defaultMarketingOffice : this.defaultOffice;
  }

  @computed('defaultPhoneNumber', 'hasPracticeWebsite', 'practiceWebsite.practicePhone')
  get phone() {
    if (this.hasPracticeWebsite) {
      return this.practiceWebsite.practicePhone;
    }
    return this.defaultPhoneNumber;
  }

  @computed('hasOffice', 'hasPracticeWebsite', 'showSectionLocations')
  get showLocation() {
    if (this.hasPracticeWebsite) {
      return this.showSectionLocations && this.hasOffice;
    }
    return this.hasOffice;
  }

  @computed(
    'hasPracticeWebsite',
    'practiceWebsite.{facebookUrl,twitterUrl,instagramUrl,yelpUrl,youtubeUrl,linkedinUrl}'
  )
  get socialMediaLinks() {
    if (!this.hasPracticeWebsite) {
      return undefined;
    }

    let { practiceWebsite } = this;

    return [
      {
        title: 'Facebook',
        url: practiceWebsite.facebookUrl,
      },
      {
        title: 'Twitter',
        url: practiceWebsite.twitterUrl,
      },
      {
        title: 'Instagram',
        url: practiceWebsite.instagramUrl,
      },
      {
        title: 'Yelp',
        url: practiceWebsite.yelpUrl,
      },
      {
        title: 'YouTube',
        url: practiceWebsite.youtubeUrl,
      },
      {
        title: 'LinkedIn',
        url: practiceWebsite.linkedinUrl,
      },
    ].filterBy('url');
  }

  @action
  _mixpanelTrack(message) {
    if (this.mixpanelTrack) {
      return this.mixpanelTrack(message);
    }

    this.mixpanel.track(`cp_footer: ${message}`);
  }

  @action
  trackAccessOn(linkName) {
    let linkDescription;

    switch (linkName) {
      case 'Privacy':
        linkDescription = 'privacy policy';
        break;
      case 'Terms':
        linkDescription = 'terms';
        break;
      case 'License':
        linkDescription = 'license agreement';
        break;
      case 'Help Center':
        linkDescription = 'help center';
        break;
      default:
        linkDescription = 'unknown';
    }

    this.mixpanel.track(`client: ${linkDescription} link accessed`, { origin: 'client_portal' });

    let eventName = 'Footer Link Accessed';
    let eventProperties = {
      object: 'Footer Link',
      action: 'Accessed',
      'link_type': linkDescription,
    };

    this.session.currentClient
      ? this.analytics.track(eventName, eventProperties)
      : this.analytics.trackAnonymously(eventName, eventProperties);
  }

  @action
  openCookiePreferences() {
    window.Osano?.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }

  @action
  showContactForm() {
    if (this.featurePracticeContactForm) {
      this.websiteWidget.openContactWidgetTask.perform();
    } else {
      this.toggleContactModal();
    }
  }
}
